import { GetCities } from './cityServices';
import { GetUserLocation } from './locationServices';
import { GetSelectOptions, GetAllSelectOptions, GetSelectPopupContent } from './selectServices';
import { GetWalletOptions, SelectingWalletPackage, CheckWalletPurchaseStatus } from './walletServices';
import {
    ResetPassword,
    UpdatePassword,
    Ping,
    UserLogin,
    TerminalLogin,
    GetUser,
    GetUserWithMemoizer,
    GetUserWithToken,
    GetMagicSignInToken,
    GetAppDownloadLink,
    GetPaymentMethods,
    DeletePaymentMethod,
    GetPastOrders,
    GetUpcomingOrders,
    AccessTokenStorage,
    GetOtp,
    VerifyWithMobileNumber,
    GetAddresses,
    DeleteAddress,
    GetTimeslots,
    GetTimeslotsByItems,
    LoginWithFacebook,
    LoginWithGoogle,
    GetInfo,
    CreateNewUser,
    CheckUsername,
    StoreFreshChatId,
    UpdatePartnerIntegration,
    UpdateUser,
    UpdateUserName,
    GetWalletStatus,
    SetAutoTopup,
    SetLanguage,
    GetNotificationSettings,
    SetNotificationSettings,
} from './userServices';
import {
    GetOrderSummary,
    PlaceOrder,
    PlaceMealOrder,
    CheckPurchaseStatus,
    SendPaymentIntentID,
    checkWalletID,
} from './orderServices';
import {
    GetClientToken,
    CreateCreditCard,
    CreateAdyenPaymentMethod,
    CreateCreditCardOmise,
    GetPaymentTime,
    StoreStripeToken,
    ConfirmSetupIntent,
    CreateSelectSubscription,
} from './paymentServices';
import {
    InitFacebook,
    HasLoggedInFacebook,
    LogInFacebook,
    LogOutFacebook,
    GetFacebookProfile,
} from './facebookServices';
import {
    GetMenuTimeSettings,
    GetMenuForDateRange,
    GetMenuDates,
    GetMenu,
    GetTerminalMenuForDateRange,
    InvalidateInCartOrder,
    GetAddonsForUpsell,
    GetTerminalComboItemsForDateRange,
    GetAddOnForDate,
    GetDish,
    GetReviewsForDish,
    GetTerminalDish,
    CheckCart,
    GetContentCards,
} from './menuService';
import { UserCart } from './userCartServices';
import {
    EditAddress,
    CreateAddress,
    GetDeliveryAreaPolygon,
    VoteAddress,
    ValidateAddressCoordinate,
    ExtractPlaceDetails,
    GetAddressDetails,
    AddressAutocomplete,
} from './addressServices';
import { AnnouncementStorage } from './announcementServices';
import { InitGoogle } from './googleServices';
import { RequestCode, VerifyCode } from './phoneVerificationServices';
import {
    GetTerminalInfo,
    UpdateTerminalInfo,
    PlaceTerminalOrder,
    PosPricesUpdate,
    UpdateInCartOrder,
    CheckCouponValidity,
    PlaceTerminalOrderViaPos,
    CheckPaymentStatus,
    GetAccountExistInfo,
    SendLogToBackend,
    RewardRedeemCode,
    ValidateCoupon,
    CashPaymentReorder,
    ResendOTP,
    RefreshQRCode,
    EwalletStatusCheck,
    GetDealComboOption,
    SendOTP,
} from './terminalServices';
import {
    GetDeliveryStatus,
    GetDeliveryDetail,
    SubmitRatingInfo,
    GetRatingInfo,
    GetDeliveryPreferences,
    CancelOrder,
    UpdateDelivery,
} from './deliveryServices';
import { SubmitBusinessForm, CreateFreshDeskRequest } from './otherServices';
import { GetCredits, GetRewardRules, RedeemCode, GetAllRewards, GetNextReward } from './rewardsServices';
import { SubmitCode } from './rockchopServices';
import { UserAddressSettings } from './userAddressServices';

export default {
    // selectServices
    GetSelectOptions,
    GetAllSelectOptions,
    GetSelectPopupContent,
    CashPaymentReorder,
    // cityServices
    GetCities,
    CheckCouponValidity,
    CheckPaymentStatus,
    //locationServices
    GetUserLocation,
    ValidateCoupon,
    // walletServices
    GetWalletOptions,
    SelectingWalletPackage,
    CheckWalletPurchaseStatus,
    SendOTP,
    // userServices
    Ping,
    UserLogin,
    TerminalLogin,
    GetUser,
    GetUserWithMemoizer,
    RefreshQRCode,
    GetUserWithToken,
    GetMagicSignInToken,
    GetAppDownloadLink,
    VerifyWithMobileNumber,
    AccessTokenStorage,
    ResendOTP,
    GetPaymentMethods,
    DeletePaymentMethod,
    GetPastOrders,
    GetUpcomingOrders,
    GetAddresses,
    DeleteAddress,
    GetTimeslots,
    GetTimeslotsByItems,
    LoginWithFacebook,
    LoginWithGoogle,
    ResetPassword,
    UpdatePassword,
    GetInfo,
    CreateNewUser,
    CheckUsername,
    UpdateUser,
    UpdateUserName,
    StoreFreshChatId,
    UpdatePartnerIntegration,
    GetWalletStatus,
    SetAutoTopup,
    SetLanguage,
    GetOtp,
    GetNotificationSettings,
    GetDealComboOption,
    SetNotificationSettings,
    PosPricesUpdate,
    UpdateInCartOrder,
    // orderServices
    GetOrderSummary,
    PlaceMealOrder,
    PlaceOrder,
    CheckPurchaseStatus,
    checkWalletID,
    // paymentServices
    GetClientToken,
    CreateCreditCard,
    CreateAdyenPaymentMethod,
    CreateCreditCardOmise,
    GetPaymentTime,
    StoreStripeToken,
    ConfirmSetupIntent,
    CreateSelectSubscription,
    // facebookServices
    InitFacebook,
    HasLoggedInFacebook,
    LogInFacebook,
    LogOutFacebook,
    GetFacebookProfile,

    // menu services
    GetMenuTimeSettings,
    GetMenuForDateRange,
    GetMenuDates,
    GetMenu,
    GetTerminalMenuForDateRange,
    InvalidateInCartOrder,
    GetAddonsForUpsell,
    GetTerminalComboItemsForDateRange,
    GetAddOnForDate,
    GetDish,
    GetReviewsForDish,
    GetTerminalDish,
    CheckCart,
    GetContentCards,

    // user cart services
    UserCart,

    UserAddressSettings,
    EwalletStatusCheck,
    // address
    EditAddress,
    CreateAddress,
    GetDeliveryAreaPolygon,
    VoteAddress,
    ValidateAddressCoordinate,
    ExtractPlaceDetails,
    AddressAutocomplete,
    GetAddressDetails,
    // announcement services
    AnnouncementStorage,

    // Google services
    InitGoogle,

    // Phone verification services
    RequestCode,
    VerifyCode,

    // Terminal services
    GetTerminalInfo,
    UpdateTerminalInfo,
    PlaceTerminalOrder,
    PlaceTerminalOrderViaPos,
    GetAccountExistInfo,
    SendLogToBackend,
    RewardRedeemCode,
    SendPaymentIntentID,
    // Rewards services
    GetCredits,
    GetRewardRules,
    RedeemCode,
    GetAllRewards,
    GetNextReward,
    // Delivery services
    GetDeliveryStatus,
    GetDeliveryDetail,
    GetRatingInfo,
    SubmitRatingInfo,
    GetDeliveryPreferences,
    CancelOrder,
    UpdateDelivery,

    // Other services
    SubmitBusinessForm,
    CreateFreshDeskRequest,

    //rockchopServices
    SubmitCode,
};
