import React from 'react';

import LargeItem from './Items/LargeItem';
import MediumItem from './Items/MediumItem';
import SmallItem from './Items/SmallItem';

const base = 'terminal-main-items';

export default function MainItems({
    items,
    onAddToCart,
    comboItem,
    comboPrice,
}) {
    const largeItem = items[0];
    const mediumItems = items.slice(1, 4);
    const smallItemsScreen1 = items.slice(4, 10);
    const smallItemsGrid = items.slice(10, 40);
    const itemXMargin = 7;
    const itemYMargin = 7;
    const widthOfItems = 238;

    return (
        <div className={`${base}`}>
            <div className={`${base}__menu_screen_1`}>
                <div className={`${base}__screen1_rhs`}>
                    <LargeItem
                        showBestSeller={true}
                        item={largeItem}
                        comboPrice={comboPrice}
                        width={widthOfItems}
                        comboItem={comboItem}
                        height={582}
                        borderRadius={'3px'}
                        itemWithBlackBorder={false}
                        itemXMargin={itemXMargin}
                        itemYMargin={itemYMargin}
                        onAddToCart={onAddToCart}
                    />
                </div>
                <div className={`${base}__screen1_lhs`}>
                    <div className="medium-items">
                        {mediumItems.map((item) => (
                            <MediumItem
                                key={item.menu_item_id}
                                item={item}
                                comboPrice={comboPrice}
                                width={widthOfItems}
                                comboItem={comboItem}
                                height={280}
                                borderRadius={'3px'}
                                itemWithBlackBorder={false}
                                itemXMargin={itemXMargin}
                                itemYMargin={itemYMargin}
                                onAddToCart={onAddToCart}
                            />
                        ))}
                    </div>
                    <div className="small-items">
                        {smallItemsScreen1.map((item) => (
                            <SmallItem
                                key={item.menu_item_id}
                                item={item}
                                comboPrice={comboPrice}
                                width={widthOfItems}
                                height={138}
                                comboItem={comboItem}
                                borderRadius={'3px'}
                                itemWithBlackBorder={false}
                                itemXMargin={itemXMargin}
                                itemYMargin={itemYMargin}
                                onAddToCart={onAddToCart}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <div className={`${base}__small_items_grid`}>
                {smallItemsGrid.map((item) => (
                    <SmallItem
                        key={item.menu_item_id}
                        item={item}
                        comboPrice={comboPrice}
                        width={widthOfItems}
                        height={138}
                        comboItem={comboItem}
                        borderRadius={'3px'}
                        itemWithBlackBorder={false}
                        itemXMargin={itemXMargin}
                        itemYMargin={itemYMargin}
                        onAddToCart={onAddToCart}
                    />
                ))}
            </div>
        </div>
    );
}
