import React from 'react';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';

import ItemAddRemove from './ItemAddRemove';
import SwitchButton from 'lyef-switch-button';
import _ from 'lodash';
const base = 'terminal-cart-summary';

@inject('terminalCartStore')
@inject('terminalMenuStore')
@observer
export default class extends React.Component {
    state = {
        cartHeight: 0,
        decrease: false,
    };
    handleIncreaseItem = (item) => {
        this.props.terminalCartStore.addToCart(item, { add: true });
        this.props.terminalCartStore.updateInCartOrder();
        this.props.terminalCartStore.getTotalPrice();
        this.setState({ decrease: false });
    };

    handleDecreaseItem = (item) => {
        this.props.terminalCartStore.removeItemFromCart(item);
        const items = this.props.terminalCartStore.cartServices.getUniqueItemsAt();
        const comboItemFilter = items.filter((o) => {
            return (o.type == 'combo' || o.type == 'meal') && o.value_meal_menu_item.length > 0;
        });
        if (item.menu_item_id == this.props.terminalCartStore.couponMenuItemId) {
            const couponQuantity = this.props.terminalCartStore.cartServices.getQuantityOfDishOn({
                dishId: item.today_menu_id,
            });
            if (couponQuantity == 0) {
                this.props.terminalCartStore.setFreeMenuId(null);
                this.props.terminalMenuStore.setCouponDisable(false);
            }
        }

        this.setState({ decrease: true });

        if (items.length <= 2 && comboItemFilter.length == 0) {
            this.props.terminalCartStore.toggleCombo = false;
        }

        this.props.terminalCartStore.updateInCartOrder();
        this.props.terminalCartStore.getTotalPrice();
    };

    handleProceedPurchaseClicked = () => {
        const { terminalMenuStore } = this.props;

        terminalMenuStore.isShowingSummary = false;
        if (terminalMenuStore.orderType) {
            terminalMenuStore.setShowPopup(terminalMenuStore.POPUP_SNACK_DESSERT_ITEMS);
        } else {
            //splash screen hasn't showed up to customer. First ask the user to select order type and then proceed
            terminalMenuStore.setSplashScreen(true);
        }
    };

    handleToggleCutlerySwitch = () => {
        this.props.terminalCartStore.toggleCutlerySwitch();
        this.props.terminalCartStore.getTotalPrice();
    };

    expandHeight = () => {
        const { terminalCartStore } = this.props;
        const summaryContainer = document.querySelector(`.${base}__summary-container`);
        const arrowToggle = document.querySelector(`.arrow-toggle`);

        if (!terminalCartStore.toggleCombo) {

            this.setState({ decrease: false });
            // baseDiv.style.height = summaryContainer.offsetHeight + 40 + 'px';
            summaryContainer.style.overflow = 'auto';
            summaryContainer.style.maxHeight = 'calc(100vh - 110px)';
            arrowToggle.style.transform = 'rotate(180deg)';

            terminalCartStore.toggleCombo = true;
        } else if (terminalCartStore.toggleCombo) {
            this.setState({ decrease: false });
            terminalCartStore.toggleCombo = false;
            arrowToggle.style.transform = 'rotate(0)';
        }
    };

    renderSummary(addonpopup) {
        const { terminalCartStore, terminalMenuStore } = this.props;
        let items;
        let height = 0;
        let last2height = 0;

        if (addonpopup) {
            items = terminalCartStore.cartServices.getUniqueItemsAt().filter((o, i) => {
                return i < terminalCartStore.cartServices.getUniqueItemsAt().length - 1;
            });
        } else {
            items = terminalCartStore.cartServices.getUniqueItemsAt();
        }

        const comboItemFilter = items.filter((o) => {
            return (o.type == 'combo' || o.type == 'meal') && o.value_meal_menu_item.length > 0;
        });
        setTimeout(() => {
            const mainbase = document.querySelector(`.${base}`);
            const ctaContainer = document.querySelector(`.${base}__CTA-container`);
            const summaryContainer = document.querySelector(`.${base}__summary-container`);
            const allItems = document.querySelectorAll(`.${base}__item`);

            for (var i = 0; i < allItems.length; i++) {

                height += allItems[i].offsetHeight;

            }

            if (allItems.length >= 3) {
                last2height = allItems[allItems.length - 1].offsetHeight + allItems[allItems.length - 2].offsetHeight + 88;

            }


            if (summaryContainer.offsetHeight < ctaContainer.offsetHeight) {
                mainbase.style.height = summaryContainer.offsetHeight + ctaContainer.offsetHeight + 'px';
            } else {
                mainbase.style.height = summaryContainer.offsetHeight + 58 + 'px';
            }

            summaryContainer.style.maxHeight = 'calc(100vh - 110px)';


            if (summaryContainer && items.length >= 3) {
                ctaContainer.style.marginBottom = '80px';

                if (!terminalCartStore.toggleCombo && !addonpopup) {
                    terminalCartStore.toggleCombo = false;
                    mainbase.style.height = last2height + "px";
                    summaryContainer.style.bottom = 0;
                    summaryContainer.style.overflow = 'hidden';
                    ctaContainer.style.marginBottom = '80px';
                    summaryContainer.style.transform = `translateY(calc(100% - ${height + 58}px))`;
                    // summaryContainer.style.maxHeight = 'inherit';

                } else if (!addonpopup) {
                    summaryContainer.style.bottom = 0;
                    summaryContainer.style.transform = `translateY(calc(100% - ${
                        summaryContainer.offsetHeight + 58
                    }px))`;

                    mainbase.style.height = summaryContainer.offsetHeight + 58 + 'px';
                }
            } else if (comboItemFilter.length > 0) {
                summaryContainer.style.bottom = 0;
                ctaContainer.style.marginBottom = '80px';
                summaryContainer.style.transform = `translateY(calc(100% - ${summaryContainer.offsetHeight + 58}px))`;

            } else {
                summaryContainer.style.bottom = 0;
                summaryContainer.style.transform = `translateY(0)`;
                ctaContainer.style.marginBottom = '30px';
            }
        }, 0);

        return items.map((item, i) => {
            const itemQuantity = terminalCartStore.cartServices.getQuantityOfDishOn({
                dishId: item.today_menu_id,
            });



            return (
                <ItemAddRemove
                    key={i + 'n'}
                    item={item}
                    items={items}
                    toggleCombo={terminalCartStore.toggleCombo}
                    itemQuantity={itemQuantity}
                    onClickIncrease={this.handleIncreaseItem}
                    onClickDecrease={this.handleDecreaseItem}
                    firstItem={i === 0}
                />
            );
        });
    }

    render() {
        const { terminalCartStore, terminalMenuStore } = this.props;

        const isEmpty = terminalCartStore.cartServices.isEmptyAt({
            date: terminalMenuStore.menuDate,
        });
        const isAddonPopupOpen = terminalMenuStore.showingPopup === terminalMenuStore.POPUP_ADDON_ITEMS;

        let items;

        if (isAddonPopupOpen) {
            items = terminalCartStore.cartServices.getUniqueItemsAt().filter((o, i) => {
                return i < terminalCartStore.cartServices.getUniqueItemsAt().length - 1;
            });
        } else {
            items = terminalCartStore.cartServices.getUniqueItemsAt();
        } 



        const comboItemFilter = items.filter((o) => {

            return (o.type == 'combo' || o.type == 'meal') && o.value_meal_menu_item.length > 0;
        });

        document.body.style.overflow = 'auto';
        const isCutleryProvided = terminalCartStore.isCutleryProvided;

        const { isShowingSummary } = terminalMenuStore;

        let comboMealFilter;
        items.map((o) => {
            if (o.type == 'combo') {
                comboMealFilter = o.value_meal_menu_item.filter((u) => {
                    if (u.value.item_type == "Item") {

                        return u.value.item_type == "Item";

                    }
                })
            }


        })

        const showCutlerySwitcher = terminalMenuStore.orderType == 'TAKE_AWAY' && (items.filter((o) => { return o.item_type == "Combo" || o.type == "meal" }).length > 0 || (comboMealFilter && comboMealFilter.length > 0));
        const showTakeAwayRemark = showCutlerySwitcher;
        const totalPrice = terminalCartStore.totalPrice;


        return (
            <>
                <div className={isEmpty || isAddonPopupOpen || !isShowingSummary ? `${base} ${base}--hidden` : base}>
                    {items.length >= 3 || comboItemFilter.length > 0 ? (
                        <div
                            onClick={(e) => {
                                this.expandHeight();
                            }}
                            className={`${base}__expand-toggle`}
                        >
                            {terminalCartStore.toggleCombo ? 'Hide' : 'See all'}
                            <div
                                style={{
                                    transform:
                                        terminalCartStore.toggleCombo
                                            ? 'rotate(180deg)'
                                            : 'rotate(0)',
                                }}
                                className="arrow-toggle"
                            >
                                <img src="/purple-up.png" />
                            </div>
                        </div>
                    ) : null}

                    <div style={{ overflow: 'hidden' }} className={`${base}__content container display--flex`}>
                        <div style={{ position: 'relative' }} className={`${base}__summary-wrapper`}>
                            <div className={`${base}__summary-container`}>{this.renderSummary(isAddonPopupOpen)}</div>
                        </div>

                        <div className={`${base}__CTA-container `}>
                            {showCutlerySwitcher && (
                                <div className={`${base}__cutlery-switcher`}>
                                    <div
                                        style={{ marginBottom: '10px' }}
                                        className="display--flex flex-align-items-center justify--space-between"
                                    >
                                        <div className={`${base}__cutlery-text`}>
                                            <p className={`${base}__heading mbs`}>
                                                {intl.get('cutlerySelection.title')}
                                            </p>
                                            <p className={`${base}__sub-heading`}>{`${intl.get(
                                                'cutlerySelection.enabled'
                                            )}`}</p>
                                        </div>
                                        <div>
                                            <SwitchButton
                                                id={`switchTerminalCutlery`}
                                                isChecked={isCutleryProvided}
                                                action={this.handleToggleCutlerySwitch}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}

                            {showTakeAwayRemark && (
                                <div className={`${base}__takeaway-remark`}>
                                    <p className={`${base}__sub-heading`}>RM1 takeaway packaging charge is included</p>
                                </div>
                            )}
                            <div
                                style={{
                                    display: 'flex',
                                    color: '#7056B5',
                                    flexDirection: 'column',
                                }}
                            >
                                {terminalMenuStore.couponDisable && !terminalCartStore.couponErrMessage ? (
                                    <div
                                        style={{
                                            color: '#7056B5',
                                            textAlign: 'left',
                                            fontWeight: 600,
                                        }}
                                    >
                                        Code applied: {terminalMenuStore.couponTxt}{' '}
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            color: '#F4594F',
                                            maxWidth: '238px',
                                            fontWeight: 600,
                                            display: terminalCartStore.couponErrMessage ? 'block' : 'none',
                                            marginTop: '24px',
                                        }}
                                    >
                                        {terminalCartStore.couponErrMessage}
                                    </div>
                                )}

                                <div
                                    style={{ marginTop: '10px' }}
                                    className={`${base}__CTA cursor-pointer`}
                                    onClick={this.handleProceedPurchaseClicked}
                                >
                                    <p className={`${base}__CTA-text text--bolder`}>
                                        <span style={{ whiteSpace: 'nowrap' }} id="summaryCta">
                                            {intl.get('terminal.page.cartSummary.cta')}
                                        </span>
                                        <span className="separator-line"></span>
                                        <span style={{ fontSize: '24px' }} id="totalPrice">
                                            {totalPrice}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
