import React, { Component } from 'react';
import ButtonUI from '../../core/loginPopup/ButtonUI';
import {
    MEDIUM_SCREEN_MEDIA,
    SMALL_SCREEN_MEDIA,
} from '../../../services/constants';
import intl from 'react-intl-universal';

const base = 'careers__section-hero';

export default class SectionHero extends Component {
    onClick = () => {
        window.open('https://popmeals.freshteam.com/jobs', '_blank');
    };

    render() {
        const smallAndMediumScreens =
            window.matchMedia(SMALL_SCREEN_MEDIA).matches ||
            window.matchMedia(MEDIUM_SCREEN_MEDIA).matches;

        return (
            <div className={`${base}`}>
                <div className={`${base}__background hide--if-small`} />
                <div className={`${base}__img`} />
                <div
                    className={`${base}__box-container ${
                        smallAndMediumScreens ? '' : 'container'
                    }`}
                >
                    <div className={`${base}__text-container`}>
                        <div className={`${base}__text`}>
                            <h1 className={`${base}__heading text--bolder`}>
                                {intl.get('careers.hero.heading')}
                            </h1>
                            <p className={`${base}__sub-heading`}>
                                {intl.get('careers.hero.subheading')}
                            </p>
                            <p
                                className={`${base}__jobs-available-text text--bolder`}
                            >
                                {intl.get('careers.hero.jobsAvailable')}
                            </p>
                            <ButtonUI
                                customClasses={`${base}__cta button--success`}
                                text={intl.get('careers.hero.cta')}
                                onClick={this.onClick}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
