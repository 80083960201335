import React from 'react';

import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';

const base = 'terminal-payment-option';
@inject('terminalCartStore')
@inject('terminalMenuStore')
@observer
export default class extends React.Component {
    handlePaymentOption = (type) => {
        const { showOnMobile, terminalMenuStore } = this.props;
        if (type == 'ewallet' && !terminalMenuStore.backBtn) {
            terminalMenuStore.paymentType = 'Kiosk_Ewallet';
            terminalMenuStore.submitQRPayment(showOnMobile, type);
        } else if (type == 'ewallet' && terminalMenuStore.backBtn) {
            console.log('show popup qr code', terminalMenuStore.showPopup);
            terminalMenuStore.setShowPopup(terminalMenuStore.POPUP_QRCODE);
            terminalMenuStore.backBtn = false;
        } else {
            const { eligibleForLuckyWheelAfterTableNum, askForName } =
                terminalMenuStore;
            const isNewUser = askForName;
            const outletName = localStorage.getItem('terminalUserName') || null;
            const deviceId = terminalMenuStore.KIOSK_RANDOM_ID || null;
            terminalMenuStore.paymentType = 'Pos';
            if (terminalMenuStore.backBtn) {
                terminalMenuStore.cashReorder();
            } else {
                terminalMenuStore.confirmOrder(
                    showOnMobile,
                    isNewUser,
                    eligibleForLuckyWheelAfterTableNum,
                    outletName,
                    deviceId
                );
            }
        }
    };
    render() {
        return (
            <div className={base}>
                <div style={{ marginTop: '20px' }} className={`${base}__title`}>
                    <p> {intl.get('terminal.qrPopup.payment_title')}</p>
                </div>
                <div style={{ display: 'flex', marginTop: '24px' }}>
                    <div
                        onClick={() => this.handlePaymentOption('ewallet')}
                        className={`${base} payment-card`}
                        style={{
                            width: '50%',
                            border: '3px solid #00C6BF',
                            borderRadius: '8px',
                        }}
                    >
                        <div style={{ padding: '16px', height: '100%' }}>
                            <img
                                style={{ width: '100%', height: '100%' }}
                                src="/ewallet.png"
                            />
                        </div>

                        <div
                            style={{
                                fontWeight: 600,
                                fontSize: '24px',
                                color: '#00C6BF',
                                paddingBottom: '19px',
                            }}
                        >
                            {intl.get('terminal.qrPopup.ewallet_title')}
                        </div>
                    </div>
                    <div
                        className={`${base} payment-card`}
                        style={{
                            width: '50%',
                            border: '3px solid #D3A604',
                            borderRadius: '8px',
                        }}
                        onClick={() => this.handlePaymentOption('counter')}
                    >
                        <div style={{ padding: '16px', height: '100%' }}>
                            <img
                                style={{ width: '100%', height: '100%' }}
                                src="/payCounter.png"
                            />
                        </div>

                        <div
                            style={{
                                fontWeight: 600,
                                fontSize: '24px',
                                color: '#D3A604',
                                paddingBottom: '19px',
                            }}
                        >
                            {intl.get('terminal.qrPopup.counter_pay_title')}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
