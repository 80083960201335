import React from 'react';
import { inject, observer } from 'mobx-react';

import Entry from './Entry';
import MobileEntry from './mobile/Entry';
import SplashScreen from './SplashScreen';
import LuckyWheelOrderConfScreen from './LuckyWheelOrderConfScreen';
import { SMALL_SCREEN_MEDIA } from '../../../services/constants';
import { GetQueryParamFromUrl } from '../../../services/url';
import { withRouter } from 'react-router-dom';

@inject('terminalMenuStore', 'terminalCartStore')
@observer
class MainEntry extends React.Component {
    componentDidMount() {
        const { terminalMenuStore } = this.props;

        if (!terminalMenuStore.itemsFetcher) {
            terminalMenuStore.fetchTerminalInfo().then(() => terminalMenuStore.fetchMenu());
            terminalMenuStore.setOrderTypeIfExistsAndReloadMenu(GetQueryParamFromUrl('orderType'));
        }
    }

    handleShowSplash = () => {
        const { terminalMenuStore, history } = this.props;

        if (
            !terminalMenuStore.pinValidation &&
            terminalMenuStore.paymentType != 'Kiosk_Ewallet' &&
            terminalMenuStore.orderType
        ) {
            terminalMenuStore.setCouponTxt('');
            terminalMenuStore.setCouponDisable(false);
            terminalMenuStore.setCouponErrMessage(null);
            terminalMenuStore.showingPopup = 'reset-kiosk';
            console.log(terminalMenuStore.showingPopup, 'showing popup');
            // history.push('/terminal');
            // terminalMenuStore.showSplashScreenActions(GetQueryParamFromUrl('orderType'));
        }
    };

    render() {
        const { showSplashScreen, showLuckyWheelScreen } = this.props.terminalMenuStore;
        const isSmallScreen = window.matchMedia(SMALL_SCREEN_MEDIA).matches;
        const showFullScreen = (showSplashScreen || showLuckyWheelScreen) && !isSmallScreen;
        return (
            <React.Fragment>
                <div
                    style={{
                        display: `${showFullScreen ? 'none' : 'block'}`,
                    }}
                >
                    {isSmallScreen ? <MobileEntry /> : <Entry />}
                </div>
                <div
                    style={{
                        display: `${showFullScreen ? 'block' : 'none'}`,
                    }}
                >
                    {showSplashScreen && (
                        <SplashScreen isSmallScreen={isSmallScreen} onIdleTimeReached={this.handleShowSplash} />
                    )}
                    {showLuckyWheelScreen && <LuckyWheelOrderConfScreen />}
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(MainEntry);
